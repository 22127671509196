// Import the functions you need from the SDKs you need
import React from "react";

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const isBrowser = () => typeof window !== "undefined";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2mTJNgQvTFdMVv_uF7tFtUvSd-xByG8I",
  authDomain: "ert.credit",
  projectId: "ertc-b0d32",
  storageBucket: "ertc-b0d32.appspot.com",
  messagingSenderId: "482989883644",
  appId: "1:482989883644:web:16f20ed4fea31d17fc1779",
  measurementId: "G-XDXQTVDTQP"
};
if (isBrowser()) {
  firebase.initializeApp(firebaseConfig);
}

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/admin',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
};

function SignInScreen() {
  return (
    <div>
      <h1>ERT.credit</h1>
      <p>Please sign-in:</p>
      {isBrowser() &&
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      }
    </div>
  );
}
export { SignInScreen, firebase }

