import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { firebase } from '../../../../components/firebase';
import Login from './components/LoginModal';

import { NavItem, ThemeModeToggler } from './components';


const Topbar = ({ onSidebarOpen, pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    account: accountPages,
  } = pages;
  const showTemplate = false;

  const handleLogout = () => {
    firebase.auth().signOut();
  }

  const handleLoginClicked = () => {
    setLoginOpen(true);
  }
  const [user, setUser] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);

  useEffect(() => {
    if (window) {
      firebase.auth().onAuthStateChanged(function (u) {
        if (u) {
          setUser(u);
          setLoginOpen(false);
        }
        else {
          setUser(null);
        }
      });
    }

  }, []);

  return (
    <>
      <Login open={loginOpen} />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={1}
      >
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="FAST Employee Retention Credit"
          width={{ xs: 140, md: 150 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? 'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Flogos%2F3.png?alt=media&token=6989864c-5069-4953-84a4-09c36f481b50'
                : 'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Flogos%2F1-negative.png?alt=media&token=a15f46c0-9b66-4d5f-b94b-bf1ba02bd610'
            }
            height={1}
            width={1}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
          <Box>
            <Link
              underline="none"
              component="a"
              href="/about"
              color="text.primary"
            >
              About Us
            </Link>
          </Box>
          {showTemplate &&
            <div>
              <Box marginLeft={3}>
                <NavItem
                  title={'Pages'}
                  id={'secondary-pages'}
                  items={secondaryPages}
                />
              </Box>
              <Box marginLeft={3}>
                <NavItem
                  title={'Account'}
                  id={'account-pages'}
                  items={accountPages}
                />
              </Box>
              <Box marginLeft={3}>
                <Link
                  underline="none"
                  component="a"
                  href="/docs/introduction"
                  color="text.primary"
                >
                  Documentation
                </Link>
              </Box>
            </div>}
          <Box marginLeft={3}>
            <ThemeModeToggler />
          </Box>
          <Box marginLeft={3}>
            {!user && <Button
              variant="contained"
              color="primary"
              onClick={handleLoginClicked}
              size="large"
            >
              Login
            </Button>}
            {user &&
              <Button
                variant="contained"
                color="primary"
                target="blank"
                size="large"
                onClick={handleLogout}
              >
                Logout
              </Button>
            }
          </Box>

        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
          <Box marginRight={2}>
            <ThemeModeToggler />
          </Box>
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant={'outlined'}
            sx={{
              borderRadius: 2,
              minWidth: 'auto',
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2),
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
    </>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
};

export default Topbar;
